define(['app'], (app) => {

  const fastTrackBasketInfo = () => {
    const component = {};

    const _config = {
      selectors: {
        button: '.fastTrackBasketInfo_viewAllQualifiedButton',
      },
    };

    const GA_EVENT_CATEGORY = 'Fast Track Engagement';
    const GA_EVENT_ACTION = 'Clicked View All Qualified Items';

    const _init = (element) => {
      component.element = element;
      component.button = component.element.querySelector(component.config.selectors.button);

      component.addEventListeners();
      return component;
    };

    const _addEventListeners = () => {
      component.button.addEventListener('click', component.buttonClicked);
    };

    const _buttonClicked = () => {
      app.publish('tracking/record', GA_EVENT_CATEGORY, GA_EVENT_ACTION);
    };

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.buttonClicked = _buttonClicked;

    return component;
  };

  return fastTrackBasketInfo;
});
